<template>
    <div class="app-container">
        <el-tabs :tab-position="tabPosition" style="height: 100%" v-model="tabLocation" @tab-click="tabChanged">
            <el-tab-pane v-for="section in sections" :key="section.slug" :name="section.slug">
                <span slot="label">
                    <el-badge
                        v-if="
                            typeof section.badge !== 'undefined' &&
                            hasBadge(section.badge) &&
                            parseInt(badgesState[section.badge]) > 0
                        "
                        :value="badgesState[section.badge]"
                        :is-dot="false"
                        :max="99"
                        :class="{ item: true }"
                    />
                    {{ section.label }}
                </span>
                <keep-alive>
                    <component v-if="tabLocation == section.slug" v-bind:is="section.component"></component>
                </keep-alive>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import has from 'lodash.has';
import i18n from '@/lang';
const Profile = () => import('./components/profile');
const Addresses = () => import('./components/addresses');
const Contacts = () => import('./components/contacts');
const Financial = () => import('./components/financial');
const Pages = () => import('./components/pages');
const Technical = () => import('./components/technical');
const MCC = () => import('./components/mcc');
const Security = () => import('./components/security');
const Acts = () => import('./components/acts');
export default {
    components: {
        Profile,
        Addresses,
        Contacts,
        Financial,
        Pages,
        Technical,
        MCC,
        Security,
        Acts,
    },
    data: () => ({
        tabLocation: 'profile',
        sections: [
            {
                slug: 'profile',
                label: i18n.t('account.profile.tab-profile'),
                component: 'Profile',
            },
            {
                slug: 'mcc',
                label: i18n.t('account.profile.tab-mcc'),
                component: 'MCC',
            },
            {
                slug: 'acts',
                label: i18n.t('account.profile.tab-acts'),
                component: 'Acts',
                badge: 'acts',
            },
            {
                slug: 'pages',
                label: i18n.t('account.profile.tab-pages'),
                component: 'Pages',
            },
            {
                slug: 'addresses',
                label: i18n.t('account.profile.tab-addresses'),
                component: 'Addresses',
            },
            {
                slug: 'contacts',
                label: i18n.t('account.profile.tab-contacts'),
                component: 'Contacts',
            },
            {
                slug: 'financial',
                label: i18n.t('account.profile.contact-type-financial'),
                component: 'Financial',
            },
            {
                slug: 'technical',
                label: i18n.t('account.profile.tab-technical'),
                component: 'Technical',
            },
            {
                slug: 'security',
                label: i18n.t('account.profile.tab-security'),
                component: 'Security',
            },
        ],
    }),
    methods: {
        tabChanged(tab) {
            this.$router
                .replace({
                    name: 'Informatii',
                    query: {
                        section: tab.name,
                    },
                })
                .catch(() => {});
        },
        isComposedBadge(badge) {
            if (Array.isArray(badge) === false) return false;

            switch (true) {
                case badge.length === 1:
                    if (this.badgesState[badge.join()] > 0) return true;
                    else return false;

                case badge.length > 1:
                    const badgeKey = badge.join();
                    this.badgesState[badgeKey] = 0;
                    for (const i in badge) {
                        if (has(this.badgesState, badge[i]))
                            this.badgesState[badgeKey] += parseInt(this.badgesState[badge[i]]);
                    }
                    if (this.badgesState[badgeKey] > 0) return true;
                    else return false;

                default:
                    return false;
            }
        },
        hasBadge(badge) {
            const result = this.isComposedBadge(badge) || has(this.badgesState, badge);
            return result;
        },
    },
    computed: {
        ...mapGetters({
            device: 'app/device',
            badgesState: 'app/badgesState',
        }),
        tabPosition() {
            return this.device === 'mobile' ? 'top' : 'left';
        },
    },
    created() {
        if (this.$route.query && this.$route.query.section) this.tabLocation = this.$route.query.section.trim();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card,
.el-tabs__content {
    overflow: auto !important;
}
</style>
